import $ from "jquery";
import currentSelections from "../selections/selections";
import slugify from "slugify";
const productData = require("../../_data/options.json");
const roomData = require("../../_data/rooms.json");
const { getProductImage, getAddOnImage } = require('../image-cache');

(function () {
  rebuild();
  currentSelections.subscribe(selectionsUpdated);
})();

//////////////

function rebuild() {
  const $templatesContainer = $("[data-selection-templates]").first();
  if (
    $templatesContainer.length === 0 ||
    Object.keys(currentSelections.byRoom).length === 0
  ) {
    const $empty = $templatesContainer.find("[data-no-selections]").clone();
    $templatesContainer.prev().append($empty);
    return;
  }

  Object.keys(currentSelections.byRoom).forEach((roomId) => {
    buildRoom(roomId, $templatesContainer);
  });

  $("body").trigger("resetButtons");
}

function mapProductLineAndType(roomId) {
  const productLines = {};
  Array.from(currentSelections.byRoom[roomId].finishes).forEach((finishId) => {
    const finish = productData.finishes[finishId];
    if (productLines[finish.type] === undefined) {
      productLines[finish.type] = {};
    }
    if (productLines[finish.type][finish.productLine] === undefined) {
      productLines[finish.type][finish.productLine] = [];
    }
    productLines[finish.type][finish.productLine].push(finishId);
  });
  return productLines;
}

function buildRoom(roomId, $templatesContainer) {
  const room = roomData.find((room) => room.id === roomId);
  const $header = $templatesContainer.find("[data-selection-header]").clone();
  $templatesContainer.prev().append($header);
  $header.find("[data-selection-header]").html(room.name);


  const productLines = mapProductLineAndType(roomId);

  ["cabinetry", "hardware"].forEach((type) => {
    if (!productLines[type]) {
      return;
    }
    Object.keys(productLines[type]).forEach((productLineId) =>
      buildProductLine(
        productLineId,
        productLines[type][productLineId],
        roomId,
        $templatesContainer
      )
    );
    const $button = $templatesContainer
      .find("[data-selection-more-button]")
      .clone();
    $button.find("a").attr("href", `/rooms/${slug(room.name)}#${type}`);
    $templatesContainer.prev().append($button);
  });

  buildAddOns($templatesContainer, room);
}

function buildProductLine(
  productLineId,
  finishes,
  roomId,
  $templatesContainer
) {
  const $productLine = $templatesContainer
    .find("[data-selection-product-line]")
    .clone();
  $productLine
    .find("[data-selection-product-line-name]")
    .html(productData.productLines[productLineId].name);

  const $tags = $productLine.find("[data-selection-product-line-tags]");
  const $tag = $tags.find(".tag");
  $tag.detach();
  productData.productLines[productLineId].tags.forEach((tagText) => {
    $tags.append($tag.clone().html(tagText));
  });

  const $grid = $productLine.find("[data-selection-product-line-grid]");
  const $cabinetry = $grid.find(".card--finish.cabinetry-cell");
  const $hardware = $grid.find(".card--finish.hardware-cell");
  $cabinetry.detach();
  $hardware.detach();
  finishes.forEach((finishId) => {
    const finish = productData.finishes[finishId];
    const $finish =
      finish.type === "cabinetry"
        ? $cabinetry
        : $hardware;
    const $newFinnish = $finish.clone();
    const $link = $newFinnish.find('[href="#view-product-line"]')
        .attr('data-modal-product-line-finish', finishId)
        .attr('data-modal-product-line-room', roomId);
    $newFinnish.attr("data-finish-cell", finishId);
    const $image = $newFinnish.find(".card--finish-image img");
    $image.replaceWith(getProductImage(finishId));
    $newFinnish
      .find(".card--finish-name")
      .html(productData.finishes[finishId].name);
    $newFinnish
      .find(".card--finish-heart")
      .attr("data-room", roomId)
      .attr("data-finish", finishId)
      .attr("data-tooltip", "")
      .foundation();

    $grid.prepend($newFinnish);
  });

  $templatesContainer.prev().append($productLine);
}

function buildAddOns($templatesContainer, room) {
  const roomId = room.id;
  const addOns = Array.from(currentSelections.byRoom[roomId].addOns);
  const $container = $templatesContainer
    .find("[data-selection-add-ons]")
    .clone();
  const $banner = $container
    .find(".room--add-on-banner")
    .detach();
  const $divider = $container
    .find(".my-selections--template-add-ons-divider")
    .detach();

  addOns.forEach((addOnId, i) => {
    const addon = productData['add-ons'][addOnId];
    const $newBanner = $banner.clone();
    const $grid = $newBanner.find(".my-selections--template-add-ons-grid");
    if (i % 2 === 0) {
      $newBanner.addClass('room--add-on-banner-image-on-left');
      $newBanner.removeClass('room--add-on-banner-image-on-right');
    } else {
      $newBanner.addClass('room--add-on-banner-image-on-right');
      $newBanner.removeClass('room--add-on-banner-image-on-left');
    }

    const $heading = $newBanner.find(".room--add-on-heading");
    $heading.html(addon.name);

    const $description = $newBanner.find(".room--add-on-text");
    $description.html(addon.description);

    const $image = $newBanner.find(".room--add-on-banner-image");
    $image.replaceWith(getAddOnImage(addOnId));

    const $button = $newBanner.find(".button.secondary");
    $button.attr("data-room", roomId);
    $button.attr("data-add-on", addOnId);

    if (addon.features) {
      const $featuresContainer = $newBanner.find(".room--add-on-feature-container");
      const $listItem = $featuresContainer
        .find(".room--add-on-feature-item")
        .detach(); 
      Array.from(addon.features).forEach((featureText) => {
        const $newListItem = $listItem.clone();
        $newListItem.html(featureText);
        $featuresContainer.append($newListItem)
      });
    }

    $container.append($newBanner);

    if (i < addOns.length - 1) {
      const $newDivider = $divider.clone();
      $grid.append($newDivider);
    }
  });

  $templatesContainer.prev().append($container);

  if (addOns.length > 0) {
    const $button = $templatesContainer
      .find("[data-selection-more-button]")
      .clone();
    $button.find("a").attr("href", `/rooms/${slug(room.name)}`);
    $templatesContainer.prev().append($button);
  }
}

function selectionsUpdated(_type, _action, _roomId, itemId) {
  $(`[data-finish-cell="${itemId}"]`).each((i, element) => {
    const $element = $(element);
    $element.find(".card--finish-heart").foundation("hide");
  });
  $("[data-selection-templates]").prev().html("");
  rebuild();
}

function slug(str, options = {}) {
  // Same configuration as 11ty's slugify filter
  // node_modules/@11ty/eleventy/src/Filters/Slug.js
  return slugify(
    "" + str,
    Object.assign(
      {
        replacement: "-",
        lower: true,
      },
      options
    )
  );
}

import $ from 'jquery';
const options = require('../../_data/options.json');
const roomData = require('../../_data/rooms.json');
import currentSelections from "../selections/selections";
const { getProductImage } = require('../image-cache');

const { filterLevels } = require("../../_filters/filterLevels");

const $modal = $('#product-line-modal');
$('body').on('click', '[href="#view-product-line"]', modalOpenListener);
$modal.on('click', '[href="#view-product-line"]', handleFinishDetail);
$('body').on('click', '[href="#toggle-modal-finish-selection"]', handleModalButtonClick);
currentSelections.subscribe(updateButton);

/////////////

function handleFinishDetail(event) {
    event.preventDefault();
    event.stopPropagation();
    $modal.foundation('close');
    modalOpenListener(event);
}

function updateButton() {
    const finishId = $modal.attr('data-modal-product-line-finish');
    const roomId = $modal.attr('data-modal-product-line-room');
    const isSelected = currentSelections.roomHasFinishSelected(roomId, finishId);
    const $button = $modal.find('[href="#toggle-modal-finish-selection"]');
    if(isSelected) {
        $button
            .text('Remove')
            .attr('aria-selected', 'true')
            .addClass('modal-selected');
    }else {
        $button
            .text('Add to my selections')
            .attr('aria-selected', 'false')
            .removeClass('modal-selected');
    }
}

function handleModalButtonClick(event) {
    event.preventDefault();
    const finishId = $modal.attr('data-modal-product-line-finish');
    const roomId = $modal.attr('data-modal-product-line-room');
    const isSelected = currentSelections.roomHasFinishSelected(roomId === '' ? null : roomId, finishId);
    if(isSelected) {
        currentSelections.unselectFinish(roomId === '' ? null : roomId, finishId);
    }else {
        currentSelections.selectFinish(roomId === '' ? null : roomId, finishId);
    }
}

function modalOpenListener(event) {
    event.preventDefault();
    const $element = $(event.currentTarget);
    const finishId = $element.attr('data-modal-product-line-finish');
    const roomId = $element.attr('data-modal-product-line-room');
    const finish = options.finishes[finishId];
    const productLineId = finish.productLine;
    const productLine = JSON.parse(JSON.stringify(options.productLines[productLineId]));
    const isAllRooms = !roomId;
    const finishes = isAllRooms ? getLineFinishesForAllRooms(productLineId) : getLineFinishesForRoom(productLineId, roomId);
    const finishesByLineByLevel = filterLevels(options, finishes);

    buildModal(roomId, productLine, finishesByLineByLevel, finish);
    $("body").trigger("resetButtons");
    $modal.foundation('open');
    $modal.scrollTop(0);
}

const getLineFinishesForAllRooms = (productLineId) => {
    const allFinishesInRooms = roomData
        .reduce((finishes, room) => {
            return [ ...finishes, ...room.finishes];
        }, []);
    return allFinishesInRooms
        .filter(finishId => {
            if(!options.finishes[finishId]) {
                return false;
            }
            return options.finishes[finishId].productLine === productLineId;
        });
}

function getLineFinishesForRoom(productLineId, roomId) {
    const room = roomData.find(room => room.id === roomId);
    if(!room || !room.finishes) { return []; }
    return room.finishes.filter(finishId =>  {
        if(!options.finishes[finishId]) { return false }
        return options.finishes[finishId].productLine === productLineId;
    })
}

function isCabinetType(finish) {
    return ['Cabinet', 'cabinetry'].includes(finish.type)
}

function getFinishTypeAffix(finish) {
    return isCabinetType(finish) ? 'cabinetry' : 'hardware';
}

function buildModal(roomId, productLine, finishesByLineByLevel, selectedFinish) {
    const $content = $modal.find('[data-line-content]');
    const $template = $modal.find('[data-line-template]');

    const finishTypeAffix = getFinishTypeAffix(selectedFinish);
    // reset
    $content.html('');
    const $grid = $template.clone().removeAttr('data-line-template').appendTo($content);
    $grid.addClass(`modal-${finishTypeAffix}`);
    $content.find('[data-line-left]').addClass(`${finishTypeAffix}-left-col`);
    $content.find('[data-line-right]').addClass(`${finishTypeAffix}-right-col`);
    $modal.attr('data-modal-product-line-finish', selectedFinish.id);
    $modal.attr('data-modal-product-line-room', roomId);

    // left column
    const $mainImage = $content.find('[data-line-main-image]');
    console.log('selected finish', selectedFinish);
    $mainImage.append(getProductImage(selectedFinish.id));
    $content.find('[data-line-finish-name]').html(selectedFinish.name);
    updateButton();

    // product line name
    $content.find('[data-line-name]').html(productLine.name);

    // tags
    const $tags = $content.find("[data-line-tags]");
    const $tag = $tags.find(".tag");
    $tag.detach();
    productLine.tags.forEach((tagText) => {
        $tags.append($tag.clone().html(tagText));
    });

    // description
    const $description = $content.find('[data-line-description]');
    if(productLine.description) {
        $description.html(productLine.description);
    }else {
        $description.remove();
    }

    // levels
    const $levelContainer = $content.find('[data-line-level-container]');
    const $levelTemplate = $content.find('[data-line-level]').detach();
    const $cabinetry = $levelTemplate.find(".card--finish.cabinetry-cell").detach();
    const $hardware = $levelTemplate.find(".card--finish.hardware-cell").detach();
    const $cell = isCabinetType(selectedFinish) ? $cabinetry.clone() : $hardware.clone();
    finishesByLineByLevel.forEach((level, levelI) => {
        const $level = $levelTemplate.clone();
        $level.find('[data-line-level-name]').html(level.name);
        const finishes = level.productLines.reduce((finishes, nextLine) => {
            return [ ...finishes, ...nextLine.finishes ];
        }, [])
        finishes.forEach((finish) => {
            const $newCell = $cell.clone();
            $newCell.addClass(`${getFinishTypeAffix(finish)}-cell`);
            const $image = $newCell.find('img');
            $image.replaceWith(getProductImage(finish.id));
            $newCell
                .find('[data-modal-product-line-finish]')
                .attr('src', '#view-finish')
                .attr('data-modal-product-line-finish', finish.id)
                .attr('data-modal-product-line-room', roomId);
            $newCell.appendTo($level);
            $newCell
                .find(".card--finish-name")
                .html(finish.name);
            $newCell
                .find(".card--finish-heart")
                .attr("data-room", roomId)
                .attr("data-finish", finish.id)
                .attr("data-tooltip", "")
                .foundation();
        });
        $level.appendTo($levelContainer);
    });
}
$ = require('jquery');

/**
 * Disables sticky scroll on inactive tabs.
 *
 * This fixes a bug where scrolling past the sticky element when not visible hides the element off
 * screen when you come back to the tab.
 */
$('body').on('change.zf.tabs', (ev, $el) => {
    $('.tabs-panel:not(.is-active)')
        .find('[data-sticky]')
        .each((i, element) => {
            $(element).foundation('_destroy')
        });

    $('.tabs-panel.is-active')
        .find('[data-sticky]')
        .each((i, element) => {
            console.info('Reinitializing sticky scroll element, may have lost custom initialization parameters unless they used the data- attribute approach')
            new Foundation.Sticky($(element));
        })
});
module.exports = {
  getProductImage: function(id) {
    return window.imageCache.products[id];
  },
  getAddOnImage: function(id) {
    console.log(`looking for add on ${id}`)
    console.log(`found`, window.imageCache.addOns[id])
    return window.imageCache.addOns[id];
  }
}

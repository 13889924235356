const $ = require('jquery');

$(document).ready(initCarousels);
$('body').on('change.zf.tabs', initCarousels);

function initCarousels() {
    $('[data-product-card-carousel]:not(.slick-initialized):visible').each(function(i, element) {
        const $element = $(element);

        $element.on('setPosition', function(event, slick, currentSlide, nextSlide){
            // Sync up Slick Carousel and Foundation Equalizer for matching height cards.
            // Otherwise ~2/3 Equalizer fires first and Slick resizes them to not match.
            $(document).trigger('resize');
        });

        $element.slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            speed: 300,
            adaptiveHeight: true,
            appendArrows: $element.parent().children('.carousel-nav'),
            appendDots: $element.parent().children('.carousel-nav'),
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        });
    });
}
$ = require('jquery');

/**
 * Disables sticky scroll on inactive tabs.
 *
 * This fixes a bug where opening a modal with the sticky element visible breaks the sticky disconnect
 * when close the odal and scroll.
 */
$('body').on('open.zf.reveal', (ev, $el) => {
    $('.tabs-panel.is-active')
        .find('[data-sticky]')
        .each((i, element) => {
            $(element).foundation('_destroy')
        })
});
$('body').on('closed.zf.reveal', (ev, $el) => {
    $('.tabs-panel.is-active')
        .find('[data-sticky]')
        .each((i, element) => {
            console.info('Reinitializing sticky scroll element, may have lost custom initialization parameters unless they used the data- attribute approach')
            new Foundation.Sticky($(element));
        })
});
module.exports = {
    filterLevels
}

function filterLevels(options, finishes, type) {
    if(!finishes) return [];
    const roomFinishes = type
        ? finishes.filter(finishId => options.finishes[finishId] && (options.finishes[finishId].type === type))
        : finishes;

    return Object.values(options.levels)
        .map(level => {
            level.productLines = {}
            level.finishes
                .filter(finishId => roomFinishes.includes(finishId))
                .forEach(finishId => {
                    const finish = options.finishes[finishId];
                    if(!level.productLines[finish.productLine]) {
                        const productLineClone = JSON.parse(JSON.stringify(options.productLines[finish.productLine]));
                        level.productLines[finish.productLine] = productLineClone;
                        level.productLines[finish.productLine].finishes = []
                    }
                    level.productLines[finish.productLine].finishes.push(finish);
                });
            level.productLines = Object.values(level.productLines);
            return level;
        })
        .filter(level => Object.values(level.productLines).length > 0);
}
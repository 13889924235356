function collectionToSet(arrayOrSet) {
  if (Array.isArray(arrayOrSet)) {
    return new Set(arrayOrSet);
  } else if (arrayOrSet instanceof Set) {
    return arrayOrSet;
  } else {
    return new Set();
  }
}

export default class SelectionsList {
  constructor(selectionsByRoom, allRooms = []) {
    this.byRoom = this.processByRoomInput(selectionsByRoom);
    this.allRooms = allRooms;
    this.observers = [];
  }

  processByRoomInput(data) {
    let obj = {};
    if (typeof data === "string") {
      obj = JSON.parse(data);
    } else if (
      typeof data === "object" &&
      !Array.isArray(data) &&
      data !== null
    ) {
      obj = data;
    }

    const byRoom = {};
    Object.keys(obj).forEach((roomId) => {
      roomId = this.normalizeId(roomId);
      const entry = obj[roomId];
      byRoom[roomId] = {
        finishes: collectionToSet(entry.finishes),
        addOns: collectionToSet(entry.addOns)
      }
    });

    return byRoom;
  }

  toJson() {
    const result = {};
    Object.keys(this.byRoom).map((roomId) => {
      roomId = this.normalizeId(roomId);
      const entry = this.byRoom[roomId];
      result[roomId] = {
        finishes: Array.from(entry.finishes),
        addOns: Array.from(entry.addOns),
      }
    });
    return JSON.stringify(result);
  }

  roomById(roomId) {
    roomId = this.normalizeId(roomId);
    return this.byRoom[roomId];
  }

  roomByIdOrCreate(roomId) {
    roomId = this.normalizeId(roomId);
    if (this.byRoom[roomId] === undefined) {
      this.byRoom[roomId] = {
        finishes: collectionToSet(null),
        addOns: collectionToSet(null)
      }
    }
    return this.roomById(roomId);
  }

  normalizeId(id) {
    if((id === null) || id === '') {
        return null
    }
    return id + ''; // IDs must be strings, using .attr and .data return different results for '1'
  }

  roomHasFinishSelected(roomId, finishId) {
    roomId = this.normalizeId(roomId);
    finishId = this.normalizeId(finishId);
    if(roomId !== null ) {
        const room = this.byRoom[roomId];
        return room?.finishes.has(finishId) ?? false;
    }
    return this.allRooms
        .filter(room => room.finishes.includes(finishId))
        .map(room => room.id)
        .map(roomId => {
            return this.byRoom[roomId]?.finishes.has(finishId) ?? false;
        })
        .every(hasFinish => hasFinish === true);
  }

  selectFinish(roomId, finishId) {
    roomId = this.normalizeId(roomId);
    finishId = this.normalizeId(finishId);
    const allApplicableRooms = this.allRooms
        .filter(room => room.finishes.includes(finishId))
        .map(room => room.id);
    const rooms = roomId !== null ? [roomId] : allApplicableRooms;
    rooms.forEach((roomId) => {
      const room = this.roomByIdOrCreate(roomId);
      if (!room.finishes.has(finishId)) {
        room.finishes.add(finishId);
        this.notifyFinish("added", roomId, finishId);
      }
    });
  }

  unselectFinish(roomId, finishId) {
    roomId = this.normalizeId(roomId);
    finishId = this.normalizeId(finishId);
    const rooms = roomId !== null ? [roomId] : Object.keys(this.byRoom);
    rooms.forEach((roomId) => {
      const room = this.roomById(roomId);
      if (room) {
        if (room.finishes.has(finishId)) {
          room.finishes.delete(finishId);
          this.notifyFinish("removed", roomId, finishId);
        }
        if (room.finishes.size === 0 && room.addOns.size === 0) {
          delete this.byRoom[roomId];
          this.notifyFinish();
        }
      }
    });
  }

  roomHasAddOnSelected(roomId, addOnId) {
    roomId = this.normalizeId(roomId);
    addOnId = this.normalizeId(addOnId);
    const room = this.byRoom[roomId];
    return room?.addOns.has(addOnId) ?? false;
  }

  selectAddOn(roomId, addOnId) {
    roomId = this.normalizeId(roomId);
    addOnId = this.normalizeId(addOnId);
    const allApplicableRooms = this.allRooms
        .filter(room => room.addons && room.addons.includes(addOnId))
        .map(room => room.id);
    const rooms = roomId !== null ? [roomId] : allApplicableRooms;
    rooms.forEach((roomId) => {
      const room = this.roomByIdOrCreate(roomId);
      if (!room.addOns.has(addOnId)) {
        room.addOns.add(addOnId);
        this.notifyAddOn("added", roomId, addOnId);
      }
    });
  }

  unselectAddOn(roomId, addOnId) {
    roomId = this.normalizeId(roomId);
    addOnId = this.normalizeId(addOnId);
    const rooms = roomId !== null ? [roomId] : Object.keys(this.byRoom);
    rooms.forEach((roomId) => {
      const room = this.roomById(roomId);
      if (room) {
        if (room.addOns.has(addOnId)) {
          room.addOns.delete(addOnId);
          this.notifyAddOn("removed", roomId, addOnId);
        }
        if (room.finishes.size === 0 && room.addOns.size === 0) {
          delete this.byRoom[roomId];
          this.notifyAddOn();
        }
      }
    });
  }

  subscribe(func) {
    this.observers.push(func);
  }

  unsubscribe(func) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notifyFinish(action, roomId, finishId) {
    roomId = this.normalizeId(roomId);
    finishId = this.normalizeId(finishId);
    this.observers.forEach((observer) => observer('finish', action, roomId, finishId));
  }

  notifyAddOn(action, roomId, addOnId) {
    roomId = this.normalizeId(roomId);
    addOnId = this.normalizeId(addOnId);
    this.observers.forEach((observer) => observer('add-on', action, roomId, addOnId));
  }
}

import { throttle } from 'throttle-debounce';
const $ = require('jquery');
const $body = $('body');
const $window = $(window);
const $cabinetry = $('#level-section-cabinetry');
const $hardware = $('#level-section-hardware');

(function(){
    if(($cabinetry.length === 0) && ($hardware.length === 0)) { return; }
    $window.on('scroll', throttle(500, checkFloatingCTA));
})();

///////////////

function checkFloatingCTA(event) {
    const $target = $cabinetry.is(":visible") ? $cabinetry : $hardware;
    const scrollTop = $window.scrollTop();
    const top = $target.offset().top;
    const bottom = top + $target.outerHeight() - window.innerHeight
    if ((scrollTop >= top) && (scrollTop <= bottom)) {
        $body.addClass('show-floating-cta');
    }else {
        $body.removeClass('show-floating-cta');
    }
}
// Dependencies
require('slick-carousel');

// Non-JS requirements
require('./index.scss');

// Component JS
require('./_includes/buttons');
require('./_includes/tabs-sticky');
require('./_includes/reveal');
require('./_includes/molecules/modal--product-line.js');
require('./_includes/molecules/modal--print.js');
require('./_includes/organisms/header.js');
require('./_includes/organisms/section--product-card-links.js');
require('./_includes/organisms/my-selections--template.js');
require('./_includes/selections/selections.js');

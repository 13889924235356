$ = require('jquery');

$('body').on('show.zf.dropdown', (ev, $el) => {
    if( ev.target.id === 'dropdown-mobile-menu' ) {
        $('#dropdown-overlay').show();
    }
});
$('body').on('hide.zf.dropdown', (ev, $el) => {
    if( ev.target.id === 'dropdown-mobile-menu' ) {
        $('#dropdown-overlay').hide();
    }
});
import SelectionsList from "./selections-list";
import $ from 'jquery';
const roomData = require('../../_data/rooms.json');
const options = require('../../_data/options.json');
const previousSelections = localStorage.getItem(`selections-${options.urlPrefix}`);
const currentSelections = new SelectionsList(previousSelections, roomData);
updateAllSelectionButtons(currentSelections.byRoom);
currentSelections.subscribe(handleSelectionEvents);
// currentSelections.subscribe(logEvents);
currentSelections.subscribe(save);
$('body').on('click', '[data-finish]', onHeartClick);
$('body').on('click', '[data-add-on]', onAddOnClick);
$('body').on('resetButtons', () => updateAllSelectionButtons(currentSelections.byRoom));

export default currentSelections;

///////////////

function save() {
    localStorage.setItem(`selections-${options.urlPrefix}`, currentSelections.toJson());
}

function updateAllSelectionButtons(selections) {
    unselectHearts($('[data-finish][data-tooltip]'));
    unselectButtons($('[data-add-on]'));

    Object.keys(selections).forEach((roomId) => {
        const room = selections[roomId];
        Array.from(room.finishes).forEach((finishId) => {
            $(`[data-finish=${finishId}]`).each((i, element) => {
                const $element = $(element);
                const roomId = $(element).attr('data-room');
                currentSelections.roomHasFinishSelected(roomId, finishId)
                    ? selectHearts($element)
                    : unselectHearts($element);
            });
        });
        Array.from(room.addOns).forEach((addOnId) => {
            selectButtons($(`[data-room="${roomId}"][data-add-on=${addOnId}]`));
        });
    });
}

function logEvents(action, roomId, finishId) {
    console.info(action, roomId, finishId);
    console.info(currentSelections.toJson());
}

function selectHearts($hearts, show) {
    $hearts
        .attr('aria-selected', true)
        .each((i, element) => {
            const $element = $(element);
            const tooltipId = $element.attr("data-toggle");
            $element.foundation('hide');
            $("#"+tooltipId).html("Remove from selections");
            if($element.is(':focus')) { $element.foundation('show'); }
        });
}

function unselectHearts( $hearts ) {
    $hearts
        .attr('aria-selected', false)
        .each((i, element) => {
            const $element = $(element);
            const tooltipId = $element.attr("data-toggle");
            $element.foundation('hide');
            $("#"+tooltipId).html("Add to selections");
            if($element.is(':focus')) { $element.foundation('show'); }
        });
}

function selectButtons($button) {
    $button
        .attr('aria-selected', true)
        .each((i, element) => {
            const $element = $(element);
            $element.html('Remove From Selections');
        });
}

function unselectButtons($button) {
    $button
        .attr('aria-selected', false)
        .each((i, element) => {
            const $element = $(element);
            $element.html('Add to Selections');
        });
}

function handleSelectionEvents(type, action, roomId, itemId) {
    updateAllSelectionButtons(currentSelections.byRoom);
}

function onHeartClick( event ) {
    event.preventDefault();
    const $element = $(event.currentTarget);
    const finishId = $element.data('finish');
    const roomId = $element.data('room');
    const isSelected = ($element.attr('aria-selected') === 'true');
    if(isSelected) {
        currentSelections.unselectFinish(roomId, finishId);
    }else {
        currentSelections.selectFinish(roomId, finishId);
    }
}

function onAddOnClick( event ) {
    event.preventDefault();
    const $element = $(event.currentTarget);
    const addOnId = $element.data('add-on');
    const roomId = $element.data('room');
    const isSelected = ($element.attr('aria-selected') === 'true');
    if(isSelected) {
        currentSelections.unselectAddOn(roomId, addOnId);
    }else {
        currentSelections.selectAddOn(roomId, addOnId);
    }
}
